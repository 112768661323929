<template>
  <div class="bg-v3-blue rounded-full h-fit dark:bg-v3-gray-50/20 bg-opacity-20">
      <loading v-if="!links"></loading>
      <template v-else>
        <div class="h-full p-2">
          <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
            <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
              <div>
    <!--            <navigation-link name="OnSite Dash" :is-active="isCurrentPage('/onsite-dash')" :open="panelOpen" href="/onsite-dash"  activeClass="text-v3-blue">-->
    <!--              <template v-slot:icon>-->
    <!--                <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"-->
    <!--                     viewBox="0 0 24 24">-->
    <!--                  <path fill="currentColor" d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3z"/>-->
    <!--                </svg>-->
    <!--              </template>-->
    <!--            </navigation-link>-->
                <navigation-link name="Daily Report" href="#" v-if="hasLink('oss-daily-report')" :is-active="isCurrentPage(getLinkPath('oss-daily-report').split('?')[0])" :open="panelOpen" @click="goToLinkHref('oss-daily-report')" activeClass="text-v3-white-100 bg-v3-blue dark:text-v3-gray-900" additional-classes="text-v3-blue">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.5 12C10.0304 12 10.5391 11.7893 10.9142 11.4142C11.2893 11.0391 11.5 10.5304 11.5 10C11.5 9.46957 11.2893 8.96086 10.9142 8.58579C10.5391 8.21071 10.0304 8 9.5 8C8.96957 8 8.46086 8.21071 8.08579 8.58579C7.71071 8.96086 7.5 9.46957 7.5 10C7.5 10.5304 7.71071 11.0391 8.08579 11.4142C8.46086 11.7893 8.96957 12 9.5 12Z" fill="currentColor"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V15C0.5 15.7956 0.816071 16.5587 1.37868 17.1213C1.94129 17.6839 2.70435 18 3.5 18H15.5C16.2956 18 17.0587 17.6839 17.6213 17.1213C18.1839 16.5587 18.5 15.7956 18.5 15V3C18.5 2.20435 18.1839 1.44129 17.6213 0.87868C17.0587 0.316071 16.2956 0 15.5 0H3.5ZM2.5 15V4H16.5V15C16.5 15.2652 16.3946 15.5196 16.2071 15.7071C16.0196 15.8946 15.7652 16 15.5 16H3.5C3.23478 16 2.98043 15.8946 2.79289 15.7071C2.60536 15.5196 2.5 15.2652 2.5 15Z" fill="currentColor"/>
                      </svg>
                  </template>
                </navigation-link>
                  <navigation-link name="Weekly Report" href="#" v-if="hasLink('oss-weekly-report')" :is-active="isCurrentPage(getLinkPath('oss-weekly-report').split('?')[0])" :open="panelOpen" @click="goToLinkHref('oss-weekly-report')" activeClass="text-v3-white-100 bg-v3-blue dark:text-v3-gray-900" additional-classes="text-v3-blue">
                      <template v-slot:icon>
                          <svg class="h-6 w-6 shrink-0 fill-current" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 9C14.0304 9 14.5391 8.78929 14.9142 8.41421C15.2893 8.03914 15.5 7.53043 15.5 7C15.5 6.46957 15.2893 5.96086 14.9142 5.58579C14.5391 5.21071 14.0304 5 13.5 5C12.9696 5 12.4609 5.21071 12.0858 5.58579C11.7107 5.96086 11.5 6.46957 11.5 7C11.5 7.53043 11.7107 8.03914 12.0858 8.41421C12.4609 8.78929 12.9696 9 13.5 9Z" fill="currentColor"/>
                              <path d="M5.5 9C6.03043 9 6.53914 8.78929 6.91421 8.41421C7.28929 8.03914 7.5 7.53043 7.5 7C7.5 6.46957 7.28929 5.96086 6.91421 5.58579C6.53914 5.21071 6.03043 5 5.5 5C4.96957 5 4.46086 5.21071 4.08579 5.58579C3.71071 5.96086 3.5 6.46957 3.5 7C3.5 7.53043 3.71071 8.03914 4.08579 8.41421C4.46086 8.78929 4.96957 9 5.5 9Z" fill="currentColor"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V15C0.5 15.7956 0.816071 16.5587 1.37868 17.1213C1.94129 17.6839 2.70435 18 3.5 18H15.5C16.2956 18 17.0587 17.6839 17.6213 17.1213C18.1839 16.5587 18.5 15.7956 18.5 15V3C18.5 2.20435 18.1839 1.44129 17.6213 0.87868C17.0587 0.316071 16.2956 0 15.5 0H3.5ZM2.5 15V4H16.5V15C16.5 15.2652 16.3946 15.5196 16.2071 15.7071C16.0196 15.8946 15.7652 16 15.5 16H3.5C3.23478 16 2.98043 15.8946 2.79289 15.7071C2.60536 15.5196 2.5 15.2652 2.5 15Z" fill="currentColor"/>
                              <rect x="5.5" y="5" width="8" height="4" fill="currentColor"/>
                          </svg>
                      </template>
                  </navigation-link>
                  <navigation-link name="Timesheet Editor" href="#" v-if="hasLink('oss-timesheet-builder')" :is-active="isCurrentPage(getLinkPath('oss-timesheet-builder').split('?')[0])" :open="panelOpen" @click="goToLinkHref('oss-timesheet-builder')" activeClass="text-v3-white-100 bg-v3-blue dark:text-v3-gray-900" additional-classes="text-v3-blue">
                      <template v-slot:icon>
                          <svg class="h-6 w-6 shrink-0 fill-current" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V15C0.5 15.7956 0.816071 16.5587 1.37868 17.1213C1.94129 17.6839 2.70435 18 3.5 18H15.5C16.2956 18 17.0587 17.6839 17.6213 17.1213C18.1839 16.5587 18.5 15.7956 18.5 15V3C18.5 2.20435 18.1839 1.44129 17.6213 0.87868C17.0587 0.316071 16.2956 0 15.5 0H3.5ZM2.5 15V4H16.5V15C16.5 15.2652 16.3946 15.5196 16.2071 15.7071C16.0196 15.8946 15.7652 16 15.5 16H3.5C3.23478 16 2.98043 15.8946 2.79289 15.7071C2.60536 15.5196 2.5 15.2652 2.5 15Z" fill="currentColor"/>
                              <path d="M5.5 12.1262V14.0012H7.375L12.905 8.47122L11.03 6.59622L5.5 12.1262ZM14.355 7.02122C14.4014 6.97497 14.4381 6.92002 14.4632 6.85953C14.4883 6.79905 14.5012 6.73421 14.5012 6.66872C14.5012 6.60324 14.4883 6.5384 14.4632 6.47791C14.4381 6.41742 14.4014 6.36248 14.355 6.31622L13.185 5.14622C13.1387 5.09987 13.0838 5.0631 13.0233 5.03801C12.9628 5.01291 12.898 5 12.8325 5C12.767 5 12.7022 5.01291 12.6417 5.03801C12.5812 5.0631 12.5263 5.09987 12.48 5.14622L11.565 6.06122L13.44 7.93622L14.355 7.02122Z" fill="currentColor"/>
                          </svg>

                      </template>
                  </navigation-link>
                  <navigation-link name="Custom Reports" :href="getLinkHrefWithoutHost('oss-custom-report')" v-if="hasLink('oss-custom-report')" :is-active="isCurrentPage(getLinkPath('oss-custom-report').split('?')[0])" :open="panelOpen" @click="goToLinkHref('oss-custom-reports')" activeClass="text-v3-white-100 bg-v3-blue dark:text-v3-gray-900" additional-classes="text-v3-blue">
                      <template v-slot:icon>
                          <svg class="h-6 w-6 shrink-0" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V15C0.5 15.7956 0.816071 16.5587 1.37868 17.1213C1.94129 17.6839 2.70435 18 3.5 18H15.5C16.2956 18 17.0587 17.6839 17.6213 17.1213C18.1839 16.5587 18.5 15.7956 18.5 15V3C18.5 2.20435 18.1839 1.44129 17.6213 0.87868C17.0587 0.316071 16.2956 0 15.5 0H3.5ZM2.5 15V4H16.5V15C16.5 15.2652 16.3946 15.5196 16.2071 15.7071C16.0196 15.8946 15.7652 16 15.5 16H3.5C3.23478 16 2.98043 15.8946 2.79289 15.7071C2.60536 15.5196 2.5 15.2652 2.5 15Z" fill="currentColor"/>
                              <path d="M12.9892 10.27C13.0092 10.12 13.0192 9.965 13.0192 9.8C13.0192 9.64 13.0092 9.48 12.9842 9.33L13.9992 8.54C14.0429 8.50395 14.0728 8.45385 14.0837 8.39824C14.0947 8.34262 14.086 8.28493 14.0592 8.235L13.0992 6.575C13.0709 6.52478 13.0258 6.48619 12.9718 6.46606C12.9178 6.44592 12.8584 6.44555 12.8042 6.465L11.6092 6.945C11.3592 6.755 11.0942 6.595 10.7992 6.475L10.6192 5.205C10.6103 5.14777 10.5812 5.09561 10.5372 5.05799C10.4932 5.02037 10.4371 4.9998 10.3792 5H8.45917C8.33917 5 8.24417 5.085 8.22417 5.205L8.04417 6.475C7.74917 6.595 7.47917 6.76 7.23417 6.945L6.03917 6.465C5.92917 6.425 5.80417 6.465 5.74417 6.575L4.78917 8.235C4.72917 8.34 4.74917 8.47 4.84917 8.54L5.86417 9.33C5.83917 9.48 5.81917 9.645 5.81917 9.8C5.81917 9.955 5.82917 10.12 5.85417 10.27L4.83917 11.06C4.79543 11.0961 4.76555 11.1462 4.75461 11.2018C4.74367 11.2574 4.75235 11.3151 4.77917 11.365L5.73917 13.025C5.79917 13.135 5.92417 13.17 6.03417 13.135L7.22917 12.655C7.47917 12.845 7.74417 13.005 8.03917 13.125L8.21917 14.395C8.24417 14.515 8.33917 14.6 8.45917 14.6H10.3792C10.4992 14.6 10.5992 14.515 10.6142 14.395L10.7942 13.125C11.0892 13.005 11.3592 12.845 11.6042 12.655L12.7992 13.135C12.9092 13.175 13.0342 13.135 13.0942 13.025L14.0542 11.365C14.1142 11.255 14.0892 11.13 13.9942 11.06L12.9892 10.27ZM9.41917 11.6C8.42917 11.6 7.61917 10.79 7.61917 9.8C7.61917 8.81 8.42917 8 9.41917 8C10.4092 8 11.2192 8.81 11.2192 9.8C11.2192 10.79 10.4092 11.6 9.41917 11.6Z" fill="currentColor"/>
                          </svg>


                      </template>
                  </navigation-link>
              </div>
              <div class="pt-12">
                <navigation-link name="My Dash" :open="panelOpen" href="/"  activeClass="text-v3-blue" additional-classes="text-v3-blue ring-2 ring-current">
                  <template v-slot:icon>
                    <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                    </svg>
                  </template>
                </navigation-link>
              </div>
            </ul>
          </nav>
        </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'on-site-navigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: false
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    mounted(){
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        getLinkHrefWithoutHost(key){
            return this.getFrontendRouteWithoutHostFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        getLinkPath(key){
            let linkSplit = this.links.filter(link => link.rels.contains(key)).first().href.split('/api');
            let url = linkSplit[0];
            if(linkSplit.length === 2){
                url = linkSplit[1];
            }
            return url;
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        goToLinkHref(key){
          console.log(this.links);
            let linkSplit = this.links.filter(link => link.rels.contains(key)).first().href.split('/api');
            console.log(linkSplit);
            let url = linkSplit[0];
            if(linkSplit.length === 2){
                url = linkSplit[1];
            }
            if(this.$route.path === url.split('?')[0]){
                window.location.href=url;
            }
            this.$router.push(url);
        },
    },


}
</script>
