<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>OnSite</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">
                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <on-site-navigation :links="slotProps.links"></on-site-navigation>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <div class="p-2 h-full">
                                        <HrAttendanceWeeklyPagination title="Weekly Report" :users="response.body.entities" :parent="response.body" :links="slotProps.links"></HrAttendanceWeeklyPagination>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import HrAttendancePagination from "@/v3/components/pagination/HrAttendancePagination.vue";
import HrAttendanceWeekly from "@/v3/pages/HrAttendanceWeekly.vue";
import HrAttendanceWeeklyPagination from "@/v3/components/pagination/HrAttendanceWeeklyPagination.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import OnSiteNavigation from "@/v3/components/navigation/OsNavigation.vue";
import Siren from "super-siren";

export default {
    components: {
        HrAttendanceWeeklyPagination,
        HrAttendanceWeekly,
        OnSiteNavigation,
        HrAttendancePagination,
        FetchSirenRoot,
        Loading,
        MainTemplate,
    },
    data() {
        return {
            slotProps: null,
            users: null,
            date: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        loadAttendance(slotProps){
            this.usersUrl = slotProps.links.filter(link => link.rels.includes('oss-daily-report')).first().href;
            let url = this.usersUrl;
            if(this.$route.query){
                url = this.usersUrl.split('?')[0]
            }
            let query = '';
            if(this.$route.query['date']){
                query += 'date=' + this.$route.query['date'] + '&';
            }
            if(this.$route.query['organisation_id']){
                query += 'organisation_id=' + this.$route.query['organisation_id'] + '&';
            }
            if(this.$route.query['project_id']){
                query += 'project_id=' + this.$route.query['project_id'] + '&';
            }
            if(this.$route.query['user_group']){
                query += 'user_group=' + this.$route.query['user_group'] + '&';
            }
            if(this.$route.query['user_id']){
                query += 'user_id=' + this.$route.query['user_id'] + '&';
            }
            if(this.$route.query['show_deactivated_users']){
                query += 'show_deactivated_users=' + this.$route.query['show_deactivated_users'] + '&';
            }
            if(this.$route.query['date']){
                query += 'date=' + this.$route.query['date'] + '&';
            }
            if(query){
                url = url + '?' + query;
            }

            Siren.get(url).then(res => {
                this.users = res.body;
            }, this)
            return this.users;
        },
        setDate(date){
            console.log(date);
            this.date = date;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=on_site_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=on_site_light"
        }
    }
}
</script>

